import { Link } from "react-router-dom";
import landingPageLogo from "../../assets/images/landing_page_logo.png";
import liquidImage from "../../assets/images/liquid.svg";
import liquid2Image from "../../assets/images/liquid_2.svg";
import atomImage from "../../assets/images/atom.svg";
import flowerBudImage from "../../assets/images/flower_bud.svg";
import tenantOverviewBanner from "../../assets/images/tenant_overview_banner.png";
import paymentTermsMaskImage from "../../assets/images/payment-terms-mask.png";
import insightsOverview from "../../assets/images/insights_overview.png";
import './landingPage.scss';

const LandingPage = () => {

    const getStartedClick = () => {
        const url = 'https://app.happypropy.com';
        window.open(url, '_blank');
    }

    return (
        <div className="landing-page">
            <div className="container">
                <div className="header py-4">
                    <nav className="navbar navbar-expand-lg bg-transparent navbar-transparent">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/">
                                <img src={landingPageLogo} alt="logo" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                <ul className="navbar-nav w-100">
                                    <div className="d-lg-flex align-items-center justify-content-center flex-fill">
                                        <li className="nav-item me-5">
                                            <a className="nav-link" href="#advantages">Advantages</a>
                                        </li>
                                        <li className="nav-item me-5">
                                            <a className="nav-link" href="#property-insights">Property Insights</a>
                                        </li>
                                        <li className="nav-item me-5">
                                            <a className="nav-link" href="#security">Security</a>
                                        </li>
                                    </div>    
                                    <div className="d-lg-flex align-items-center">
                                        <li className="nav-item me-5">
                                            <a className="nav-link" href="https://app.happypropy.com" target="blank">Sign in</a>
                                        </li>
                                        <li className="nav-item">
                                            <button className="btn get-started-btn" onClick={getStartedClick}>Get started now</button>
                                        </li>
                                    </div>     
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-8 one-tool-section text-center">
                        <h1>One tool for all your properties</h1>
                        <div className="short-description px-lg-5 px-4">Simplify your property management tasks effortlessly. A single solution for streamlined control and enhanced efficiency.</div>
                        <div className="d-flex justify-content-center">
                            <button className="btn get-now-banner-btn" onClick={getStartedClick}>Get started now</button>
                        </div> 
                    </div>
                    <div className="tenant-overview-image text-center">
                        <img className="img-fluid" src={tenantOverviewBanner} alt="trnant-overview" />
                    </div>
                </div> 

                <div className="best-solution-section text-center" id="advantages">
                    <button className="advantages-btn">ADVANTAGES</button>
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-center">
                            <h1>Best solution for your business</h1>
                            <div className="short-description px-lg-4 px-4">Experience firsthand the advantages of property management.</div>
                        </div>
                    </div>
                    
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row benefits-blocks">
                                <div className="col-md-6 mb-4 text-start">
                                    <div className="benefit-block">
                                        <img src={liquidImage} alt="liquid_img" />
                                        <div className="block-heading mt-3">Efficient Management</div>
                                        <div className="block-description mt-3">Simplify the intricacies of real estate management, allowing for a dedicated focus on portfolio growth. A seamless experience is ensured through a user-friendly interface.</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4 text-start">
                                    <div className="benefit-block">
                                    <img src={liquid2Image} alt="liquid_img" />
                                        <div className="block-heading mt-3">Secure Handling of Data</div>
                                        <div className="block-description mt-3">Rest assured as sensitive data, including banking details, is handled with the highest security standards. Robust encryption measures are employed to safeguard your information.</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4 text-start">
                                    <div className="benefit-block">
                                        <img src={atomImage} alt="liquid_img" />
                                        <div className="block-heading mt-3">Effortless Rent Processing</div>
                                        <div className="block-description mt-3">Eliminate the hassle of chasing rent payments. The system enables smooth rent collection with transparent records, streamlining financial management effortlessly.</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4 text-start">
                                    <div className="benefit-block">
                                        <img src={flowerBudImage} alt="liquid_img" />
                                        <div className="block-heading mt-3">Comprehensive Property Insights</div>
                                        <div className="block-description mt-3">Gain a thorough understanding of each property within your portfolio. From square footage to amenities, crucial details are readily accessible for effective management.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>  
            </div>

            <div className="property-insights-section" id="#property-insights">
                <div className="layer-1">
                    {/* <img className="img-fluid layer-bg-image w-100" src={layersGroup} /> */}
                    <div className="insights-wrapper">
                        <div className="row m-0 justify-content-center">
                            <div className="col-md-10 col-lg-7">
                                <button className="btn property-insights-btn">PROPERTY INSIGHTS</button>
                                <div className="get-more-information">Get more information about your real estate</div>
                                <p className="get-more-information-desc mb-5">Navigate seamlessly through the interface to access not only the physical attributes of each property but also the historical data that empowers strategic decision-making. Whether evaluating investment potential or ensuring optimal living conditions, the platform equips you with the insights needed for effective property management.</p>
                                <div className="tenant-overview-image">
                                    <img src={insightsOverview} className="img-fluid" alt="insight-overview" />
                                </div>
                            </div>    
                        </div>
                            
                    </div>
                </div>  
            </div>

            <div className="container my-5" id="security">
                <div className="row justify-content-center">
                    <div className="col-lg-10 security-section">
                        <div className="row">
                            <div className="col-lg-8 security-section-content">
                                <button className="btn security-btn">SECURITY</button>
                                <h2 className="mt-3 manage-realestate-heading">Manage real estate worry-free with secure banking data</h2>   
                                <p className="mt-4 manage-realestate-desc">Platform employs robust encryption measures to create a digital fortress around your sensitive data, ensuring transactions, financial records, and personal information remain confidential and shielded from unauthorized access.</p>
                                <p className="mt-4 manage-realestate-desc">With a commitment to staying ahead of evolving threats, we conduct regular security audits, updates, and continuous improvements. Trust us to fortify your information beyond industry standards, allowing you to manage your real estate portfolio with confidence and peace of mind in our secure digital stronghold.</p>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-5">
                <div className="payment-terms-section">
                    <img className="img-fluid" src={paymentTermsMaskImage} alt="mask_image" />
                    <div className="row payment-terms-content-section w-100">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6 payment-terms-content">
                            <div className="payment-terms-heading mb-4">Transparent payment terms</div>
                            <div className="payment-short-description pb-4">No suprise fees. Cancel anytime</div>

                            <div className="mt-3 d-flex align-items-end">
                                <div className="paid-yearly d-flex flex-column pe-5">
                                    <div className="save-badge mb-2">SAVE 40%</div>
                                    <div className="payment-terms-heading">$3</div>
                                    <div className="payment-short-description mb-1">per unit, paid annually</div>
                                </div>
                                <div className="paid-monthly ps-5">
                                    <div className="payment-terms-heading">$5</div>
                                    <div className="payment-short-description mb-1">per unit, paid monthly</div>
                                </div>    
                            </div>
                            <button className="btn get-started-payment-btn" onClick={getStartedClick}>Get started now</button>
                        </div>
                    </div>    
                </div>
            </div>

            <div className="footer">
                <div className="py-4 container">
                    <nav className="navbar navbar-expand-md bg-transparent navbar-transparent">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/">
                                <img src={landingPageLogo} alt="logo" />
                            </Link>
                            <ul className="navbar-nav w-100">
                                <div className="d-md-flex align-items-center justify-content-center flex-fill">
                                    <li className="nav-item me-5">
                                        <a className="nav-link" href="#advantages">Advantages</a>
                                    </li>
                                    <li className="nav-item me-5">
                                        <a className="nav-link" href="#property-insights">Property Insights</a>
                                    </li>
                                    <li className="nav-item me-5">
                                        <a className="nav-link" href="#security">Security</a>
                                    </li>
                                </div>    
                                <div className="d-md-flex align-items-center">
                                    <li className="nav-item">
                                        <button className="btn get-started-btn" onClick={getStartedClick}>Get started now</button>
                                    </li>
                                </div>     
                            </ul>
                        </div>
                    </nav>
                    <div className="mt-3 d-md-flex align-items-center justify-content-between">
                        <div className="right-reserved-text">© 2023. HappyPropy. All rights reserved</div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="nav-item me-5">
                                <a className="nav-link" href="#/">Terms of Use</a>
                            </div>
                            <div className="nav-item">
                                <a className="nav-link" href="#/">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default LandingPage;